<template>
<div class="company">

  <p>请先创建或关联企业</p>
  <van-cell class="top content" center title="创建新企业"  is-link @click="$router.push('/verified/create_company')" label="只有企业创建者才能进行场景申报和场景揭榜" />
  <van-cell class="content" center title="关联已有企业"  is-link @click="$router.push('/verified/association_company')" label="关联企业不能进行场景申报和场景揭榜" />

</div>
</template>

<script>
export default {
  name: 'company',
  mounted () {
    document.title = '创建或关联企业'

  }
}
</script>

<style scoped lang="scss">
.company{
  min-height: calc(100vh - 56px);
  width: 100vw;
  background: #F6F6F6;
  padding: 16px;



  >p{
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 16px;
  }
  >.top{
    margin-bottom: 16px;
  }
  >.content{
    padding: 18px 12px 18px 12px;
    border-radius: 4px;
  }
  >.content::v-deep{
    .van-cell__title{
      >span{
        font-size: 16px;
        //font-weight: 500;
        color: #333333;
        margin-bottom: 10px;
      }
      >.van-cell__label{
        font-size: 14px;
        //font-weight: 500;
        color: #999999;
      }
    }
  }
}
</style>
